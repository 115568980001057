import { videoGoFurtherData } from "../assets/data/video";
import { VideoGallery } from "../container/VideoGallery";
import { Link } from "react-router-dom";

export function GoFurther() {
    return (
        <div className="container">
            <section className="video-page-title">
                <div>Informations complémentaires</div>
            </section>
            <VideoGallery data={videoGoFurtherData} />
            <section className="extra-info-typo">
                <p className="light">Retrouvez <b><Link to={"/rcp"}>ici</Link></b> les caractéristiques produit</p>
            </section>
        </div>
    )
} 