import { videoReplayUniqueData } from "../assets/data/video";
import { VideoGallery } from "../container/VideoGallery";
import { Link } from "react-router-dom";

export function Replay() {
  return (
    <div className="container">
      {/* <section className="video-page-title">
                <div>Replay</div>
            </section> */}
      <VideoGallery data={videoReplayUniqueData} />
      <section className="extra-info-typo">
        <p className="light">
          Retrouvez{" "}
          <b>
            <Link to={"/rcp"}>ici</Link>
          </b>{" "}
          les caractéristiques produit
        </p>
      </section>
    </div>
  );
}
