const template = {
  title: "title of the story",
  data: {
    initial: "NodeVideoStart",
    nodes: {
      //   ------------ QUIZ 1 flow ------------
      NodeVideoStart: {
        node_type: "video",
        content: [
          "/videoInteractive_2.mp4",
          { divert: "NodeAction0" },
          { end: "00:08" }, // default : the end of the video, optional from where it end
        ],
      },

      NodeAction0: {
        node_type: "action",
        content: [
          "Suivant",
          { position: "absolute", left: "80%", top: "85%" },
          { extra: "fill,danger" },
          { divert: 'NodeVideoCase1_1' },
        ]
      },
      NodeVideoCase1_1: {
        node_type: "video",
        content: [
          "/videoInteractive_2.mp4",
          { divert: "NodeVideoCase_gravidique1_1" },
          { label: "OUVRIR" },
          { start: "00:13" },
          { end: "00:41" },
          //   { audio: "effects.webm", spriteAfter: "menu2" },
        ],
      },

      NodeVideoCase_gravidique1_1: {
        node_type: "video",
        content: [
          "/videoInteractive_2.mp4",
          { divert: "NodeAction1_1" },
          { label: "OUVRIR" },
          { start: "00:41" },
          { end: "01:05" },
          //   { audio: "effects.webm", spriteAfter: "menu2" },
        ],
      },

      NodeAction1_1: {
        node_type: "action",
        content: [
          "Suivant",
          { position: "absolute", left: "80%", top: "85%" },
          { extra: "fill,danger" },
          { divert: 'NodeAction1_2' },
          { blockNext: false, linkPath: "NodeVideoQSM1" },
        ]
      },
      NodeAction1_2: {
        node_type: "action",
        content: [
          "Comment pratiquer l'automesure",
          { extra: "fill,danger" },
          { position: "absolute", left: "35%", top: "85%" },
          { divert: 'NodeVideoPratiquer_1' },
        ]
      },

      NodeVideoPratiquer_1: {
        node_type: "video",
        content: [
          "/videoInteractive_2.mp4",
          { divert: "NodeAction1_4" },
          { label: "OUVRIR" },
          { start: "5:59" },
          { end: "6:18" },
          //   { audio: "effects.webm", spriteAfter: "menu2" },
        ],
      },
      NodeAction1_4: {
        node_type: "action",
        content: [
          "/interactive/button/suiviHTA.png",
          { image: true },
          { extra: "fill,danger" },
          { position: "absolute", left: "54%", top: "85%" },
          { divert: 'NodeAction1_3' },
          { blockNext: false, linkPath: "https://suivihta.net/", external: true },
        ]
      },
      NodeAction1_3: {
        node_type: "action",
        content: [
          "Revenir au cas",
          { extra: "fill,danger" },
          { position: "absolute", left: "77%", top: "85%" },
          { divert: 'NodeVideoCase1_1' },
        ]
      },


      NodeVideoQSM1: {
        node_type: "video",
        content: [
          "/videoInteractive_2.mp4",
          { divert: "NodeQuiz1" },
          { label: "OUVRIR" },
          { start: "00:41" },
          { end: "00:42" },
          //   { audio: "effects.webm", spriteAfter: "menu2" },
        ],
      },

      NodeQuiz1: {
        node_type: 'quiz',
        content: ['NodeQuiz6', { divert: 'nodeVideoAnserQuiz1' }, { extra: "ok,hello" }]
      },

      nodeVideoAnserQuiz1: {
        node_type: "video",
        content: [
          "/videoInteractive_2.mp4",
          { divert: "NodeAction1_5" },
          { label: "OUVRIR" },
          { start: "01:11" },
          { end: "01:13" },
          //   { audio: "effects.webm", spriteAfter: "menu2" },
        ],
      },

      NodeAction1_5: {
        node_type: "action",
        content: [
          "Suivant",
          { actionType: "TimerButton" },
          { extra: "fill,danger" },
          { position: "absolute", left: "40%", top: "85%" },
          { divert: 'NodeVideoCase2_1' },
        ]
      },




      //   ------------ QUIZ 2 flow ------------

      NodeQuiz2: {
        node_type: 'quiz',
        content: ['NodeQuiz7', { divert: 'nodeVideoAnserQuiz2' }, { extra: "ok,hello" }]
      },

      NodeVideoCase_revoire_1_1: {
        node_type: "video",
        content: [
          "/videoInteractive_2.mp4",
          { divert: "NodeVideoCase2_1" },
          { label: "OUVRIR" },
          { start: "00:12" },
          { end: "01:05" },
          //   { audio: "effects.webm", spriteAfter: "menu2" },
        ],
      },
      NodeVideoCase2_1: {
        node_type: "video",
        content: [
          "/videoInteractive_2.mp4",
          { divert: "NodeVideoPreQuiz2Enter" },
          { label: "OUVRIR" },
          { start: "01:20" },
          { end: "03:44" },
        ],
      },
      NodeVideoRecommandation2: {
        node_type: "video",
        content: [
          "/videoInteractive_2.mp4",
          { divert: "NodeActionExternReco2" },
          { label: "OUVRIR" },
          { start: "09:27" },
          { end: "10:51" },
        ],
      },

      NodeVideoRecommandation2_1: {
        node_type: "video",
        content: [
          "/videoInteractive_2.mp4",
          { divert: "NodeAction_ShowSuiz_2_1_1" },
          { label: "OUVRIR" },
          { start: "09:27" },
          { end: "10:51" },
        ],
      },
      NodeVideoPreQuiz2: {
        node_type: "video",
        content: [
          "/videoInteractive_2.mp4",
          { divert: "NodeAction_ShowSuiz_2_1_1" },
          { label: "OUVRIR" },
          { start: "03:44" },
          { end: "04:56" },
        ],
      },





      NodeVideoPreQuiz2Enter: {
        node_type: "video",
        content: [
          "/videoInteractive_2.mp4",
          { divert: "NodeQuiz2" },
          { label: "OUVRIR" },
          { start: "03:44" },
          { end: "03:46" },
        ],
      },
      nodeVideoAnserQuiz2: {
        node_type: "video",
        content: [
          "/videoInteractive_2.mp4",
          { divert: "NodeAction2_5" },
          { label: "OUVRIR" },
          { start: "04:00" },
          { end: "4:02" },
        ],
      },
      NodeVideoCase2_3: {
        node_type: "video",
        content: [
          "/videoInteractive_2.mp4",
          { divert: "NodeVideoPreQuiz2Enter" },
          { label: "OUVRIR" },
          { start: "00:12" },
          { end: "01:02" },
        ],
      },

      NodeVideoPratiquer2: {
        node_type: "video",
        content: [
          "/videoInteractive_2.mp4",
          { divert: "NodeActionExternPratiquer2" },
          { label: "OUVRIR" },
          { start: "5:59" },
          { end: "6:18" },

        ],
      },


      NodeAction_ShowSuiz_2_1: {
        node_type: "action",
        content: [
          "Suivant",
          { extra: "fill,danger" },
          { position: "absolute", left: "80%", top: "85%" },
          { divert: 'NodeAction2_2' },
          { blockNext: false, linkPath: "NodeVideoPreQuiz2" }
        ]
      },
      NodeAction2_2: {
        node_type: "action",
        content: [
          "Recommandations du Pr Hanon pour surveiller ses patients",
          { extra: "fill,danger" },
          { position: "absolute", left: "28%", top: "85%" },
          { divert: 'NodeVideoRecommandation2' },
        ]
      },

      NodeAction_ShowSuiz_2_1_1: {
        node_type: "action",
        content: [
          "Suivant",
          { extra: "fill,danger" },
          { position: "absolute", left: "80%", top: "85%" },
          { divert: 'NodeAction2_2_1_1' },
          { blockNext: false, linkPath: "NodeVideoPreQuiz2Enter" }
        ]
      },
      NodeAction2_2_1_1: {
        node_type: "action",
        content: [
          "Recommandations du Pr Hanon pour surveiller ses patients",
          { extra: "fill,danger" },
          { position: "absolute", left: "28%", top: "85%" },
          { divert: 'NodeVideoRecommandation2' },
        ]
      },

      NodeActionExternReco2: {
        node_type: "action",
        content: [
          "/interactive/button/suiviHTA.png",
          { image: true },
          { extra: "fill,danger" },
          { position: "absolute", left: "54%", top: "85%" },
          { divert: 'NodeAction_ShowSuiz_2' },
          { blockNext: false, linkPath: "https://suivihta.net/", external: true },
        ]
      },


      NodeAction_ShowSuiz_2: {
        node_type: "action",
        content: [
          "Revenir au cas",
          { extra: "fill,danger" },
          { position: "absolute", left: "77%", top: "85%" },
          { divert: 'NodeVideoPreQuiz2' },
        ]
      },

      NodeAction_ShowSuiz_2_2: {
        node_type: "action",
        content: [
          "Suivant",
          { extra: "fill,danger" },
          { position: "absolute", left: "80%", top: "85%" },
          { divert: 'NodeActionPratiquer2' },
          { blockNext: false, linkPath: "NodeVideoPreQuiz2" }
        ]
      },


      NodeActionPratiquer2: {
        node_type: "action",
        content: [
          "Comment pratiquer l'automesure",
          { extra: "fill,danger" },
          { position: "absolute", left: "35%", top: "85%" },
          { divert: 'NodeVideoCase2_1' },
        ]
      },

      NodeActionRevenir2: {
        node_type: "action",
        content: [
          "Revenir au cas",
          { extra: "fill,danger" },
          { position: "absolute", left: "77%", top: "85%" },
          { divert: 'NodeVideoCase2_3' },
        ]
      },
      NodeActionExternPratiquer2: {
        node_type: "action",
        content: [
          "/interactive/button/suiviHTA.png",
          { image: true },
          { extra: "fill,danger" },
          { position: "absolute", left: "54%", top: "85%" },
          { divert: 'NodeActionRevenir2' },
          { blockNext: false, linkPath: "https://suivihta.net/", external: true },
        ]
      },






      NodeAction2_5: {
        node_type: "action",
        content: [
          "Suivant",
          { actionType: "TimerButton" },
          { extra: "fill,danger" },
          { position: "absolute", left: "40%", top: "85%" },
          { divert: 'NodeVideoCase3_1' },
        ]
      },

      //   ------------ QUIZ 3 flow ------------
      NodeQuiz3: {
        node_type: 'quiz',
        content: ['NodeQuiz8', { divert: 'nodeVideoAnserQuiz3' }, { extra: "ok,hello" }]
      },




      NodeVideoCase_revoire_1_1_1: {
        node_type: "video",
        content: [
          "/videoInteractive_2.mp4",
          { divert: "NodeVideoCase2_1" },
          { label: "OUVRIR" },
          { start: "00:12" },
          { end: "01:05" },
          //   { audio: "effects.webm", spriteAfter: "menu2" },
        ],
      },
      NodeVideoCase_revoire_2_1: {
        node_type: "video",
        content: [
          "/videoInteractive_2.mp4",
          { divert: "NodeVideoCase3_1" },
          { label: "OUVRIR" },
          { start: "01:20" },
          { end: "03:44" },
        ],
      },


      NodeVideoCase3_1: {
        node_type: "video",
        content: [
          "/videoInteractive_2.mp4",
          { divert: "NodeAction_ShowSuiz_3_1" },
          { label: "OUVRIR" },
          { start: "04:12" },
          { end: "04:33" },
        ],
      },
      NodeVideoCase3_1_suit: {
        node_type: "video",
        content: [
          "/videoInteractive_2.mp4",
          { divert: "NodeQuiz5" },
          { label: "OUVRIR" },
          { start: "04:58" },
          { end: "05:58" },
        ],
      },
      NodeVideoRecommandation3: {
        node_type: "video",
        content: [
          "/videoInteractive_2.mp4",
          { divert: "NodeActionExternReco3" },
          { label: "OUVRIR" },
          { start: "05:59" },
          { end: "06:18" },
        ],
      },
      NodeVideoPreQuiz3: {
        node_type: "video",
        content: [
          "/videoInteractive_2.mp4",
          { divert: "NodeQuiz3" },
          { label: "OUVRIR" },
          { start: "05:58" },
          { end: "05:58" },
        ],
      },
      nodeVideoAnserQuiz3: {
        node_type: "video",
        content: [
          "/videoInteractive_2.mp4",
          { divert: "NodeAction3_5" },
          { label: "OUVRIR" },
          { start: "04:48" },
          { end: "04:49" },
        ],
      },
      NodeVideoCase3_3: {
        node_type: "video",
        content: [
          "/videoInteractive_2.mp4",
          { divert: "NodeAction_ShowSuiz_3_2" },
          { label: "OUVRIR" },
          { start: "00:12" },
          { end: "01:02" },
        ],
      },

      NodeVideoPratiquer3: {
        node_type: "video",
        content: [
          "/videoInteractive_2.mp4",
          { divert: "NodeActionExternPratiquer3" },
          { label: "OUVRIR" },
          { start: "5:59" },
          { end: "6:18" },
        ],
      },


      NodeAction_ShowSuiz_3_1: {
        node_type: "action",
        content: [
          "Suivant",
          { position: "absolute", left: "80%", top: "85%" },
          { divert: 'NodeAction3_2' },
          { extra: "fill,danger" },
          { blockNext: false, linkPath: "NodeVideoPreQuiz3" }
        ]
      },
      NodeAction3_2: {
        node_type: "action",
        content: [
          "Comment pratiquer I'automesure",
          { extra: "fill,danger" },
          { position: "absolute", left: "28%", top: "85%" },
          { divert: 'NodeVideoRecommandation3' },
        ]
      },
      NodeActionExternReco3: {
        node_type: "action",
        content: [
          "/interactive/button/suiviHTA.png",
          { image: true },
          { extra: "fill,danger" },
          { position: "absolute", left: "54%", top: "85%" },
          { divert: 'NodeAction_ShowSuiz_3' },
          { blockNext: false, linkPath: "https://suivihta.net/", external: true },
        ]
      },
      NodeAction_ShowSuiz_3: {
        node_type: "action",
        content: [
          "Revenir au cas",
          { extra: "fill,danger" },
          { position: "absolute", left: "77%", top: "85%" },
          { divert: 'NodeVideoCase3_1' },
        ]
      },

      NodeAction_ShowSuiz_3_2: {
        node_type: "action",
        content: [
          "Suivant",
          { extra: "fill,danger" },
          { position: "absolute", left: "80%", top: "85%" },
          { divert: 'NodeActionPratiquer3' },
          { blockNext: false, linkPath: "NodeVideoPreQuiz3" }
        ]
      },
      NodeActionPratiquer3: {
        node_type: "action",
        content: [
          "Comment pratiquer l'automesure",
          { extra: "fill,danger" },
          { position: "absolute", left: "35%", top: "85%" },
          { divert: 'NodeVideoCase3_1' },
        ]
      },

      NodeActionRevenir3: {
        node_type: "action",
        content: [
          "Revenir au cas",
          { extra: "fill,danger" },
          { position: "absolute", left: "77%", top: "85%" },
          { divert: 'NodeVideoCase3_3' },
        ]
      },
      NodeActionExternPratiquer3: {
        node_type: "action",
        content: [
          "/interactive/button/suiviHTA.png",
          { image: true },
          { extra: "fill,danger" },
          { position: "absolute", left: "54%", top: "85%" },
          { divert: 'NodeActionRevenir3' },
          { blockNext: false, linkPath: "https://suivihta.net/", external: true },
        ]
      },

      NodeAction3_5: {
        node_type: "action",
        content: [
          "Suivant",
          { actionType: "TimerButton" },
          { extra: "fill,danger" },
          { position: "absolute", left: "40%", top: "85%" },
          { divert: 'NodeVideoCase3_1_suit' },
        ]
      },
      //   ------------ Vote flow ------------



      NodeQuiz5: {
        node_type: 'quiz',
        content: ['NodeQuiz9', { divert: 'NodeVideoEnd' }, { extra: "ok,hello" }]
      },
      NodeVideoCase5_1: {
        node_type: "video",
        content: [
          "/videoInteractive_2.mp4",
          { divert: "NodeQuiz5" },
          { label: "OUVRIR" },
          { start: "6:20" },
          { end: "6:21" },
        ],
      },

      NodeVideoEnd: {
        node_type: "video",
        content: [
          "/videoInteractive_2.mp4",
          { divert: null },
          { label: "OUVRIR" },
          { start: "06:29" },
        ],
      },
      NodeVideoPreQuiz5: {
        node_type: "video",
        content: [
          "/videoInteractive_2.mp4",
          { divert: "NodeQuiz5" },
          { start: "10:53" },
          { end: "10:53" },
        ],
      },


    }
  },
};

export default template;
