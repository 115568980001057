import {
  Routes,
  Route,
  Outlet,
  Link,
  useParams,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { Footer } from "./container/Footer";
import { Header } from "./container/Header";
import { Main } from "./container/Main";
import { Nav } from "./container/Nav";
import { usePageTracking } from "./hooks/usePageTracking";
import { Home } from "./pages/Home";
import { InteractifPage } from "./pages/Iteractif";
import { VideothequePage } from "./pages/Videotheque";
import { ReplayPage } from "./pages/ReplayPage";
import { Replay } from "./pages/Replay";
import { BestOf } from "./pages/BestOf";
import { GoFurther } from "./pages/GoFurther";
import { RcpPage } from "./pages/Rcp";
import Videointeractive from "./video_interactive/components/interactive";
import Button from "./video_interactive/components/action/Button";
import { useEffect } from "react";
export default function App() {
  usePageTracking();
  useEffect(() => {
    function getRealVH() {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    }
    getRealVH();
    window.addEventListener("resize", getRealVH);
    return () => {
      window.removeEventListener("resize", getRealVH);
    };
  }, []);
  return (
    <>
      {/* Routes nest inside one another. Nested route paths build upon
            parent route paths, and nested route elements render inside
            parent route elements. See the note about <Outlet> below. */}
      <Routes>
        <Route path="/" element={<Page />}>
          <Route index element={<Home />} />
          <Route path="videotheque" element={<Videotheque />} />
          <Route path="replay" element={<ReplayVideosPage />} />
          <Route path="replay-video" element={<ReplayVideoPage />} />
          <Route path="best-of" element={<BestOfFunc />} />
          <Route path="allez-plus-loin" element={<GoFurtherFunc />} />
          <Route path="interactive" element={<Interactive />} />
          <Route path="rcp" element={<Rcp />} />
          {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
        </Route>
        <Route
          path="interactive/:interactiveId"
          element={<InteractiveCanvas />}
        />

        <Route path="*" element={<NoMatch />} />
      </Routes>
    </>
  );
}

function InteractiveCanvas(props) {
  let videoIds = ["1", "2"];
  let { interactiveId } = useParams();
  let exist = videoIds.includes(interactiveId);
  if (!exist) return <DispoSoon />;
  // <Navigate to="/interactive" replace />
  return (
    <div className="App">
      <div className="canvasContainer flexFont">
        <div className="canvas">
          <Videointeractive id={interactiveId} />
        </div>
      </div>
    </div>
  );
}

function Page() {
  return (
    <div className="gradient relative">
      <Header>
        <img className="logo-150" src="/images/webp/logoXO.webp" alt="" />
        <Nav />
        <img className="logo-150" src="/images/webp/logoLoxen.webp" alt="" />
      </Header>
      <Outlet />
      <Footer />
    </div>
  );
}

function Interactive() {
  return (
    <Main className={"marged"}>
      <InteractifPage />
    </Main>
  );
}

function Videotheque() {
  return (
    <Main className={"marged"}>
      <VideothequePage />
    </Main>
  );
}

function ReplayVideosPage() {
  return (
    <Main className={"marged"}>
      <ReplayPage />
    </Main>
  );
}

function ReplayVideoPage() {
  return (
    <Main className={"marged"}>
      <Replay />
    </Main>
  );
}

function BestOfFunc() {
  return (
    <Main className={"marged"}>
      <BestOf />
    </Main>
  );
}

function GoFurtherFunc() {
  return (
    <Main className={"marged"}>
      <GoFurther />
    </Main>
  );
}

function Rcp() {
  return (
    <Main>
      <RcpPage />
    </Main>
  );
}

function NoMatch() {
  return <Navigate to="/" replace />;
}

function DispoSoon() {
  const navigate = useNavigate();
  function to() {
    navigate("/interactive");
  }
  return (
    <Main className={"gradient page_404"}>
      <h1 style={{ fontSize: "5em", textAlign: "center" }}>
        Bientôt disponible
      </h1>
      <Button onClick={to} style={{ fontSize: "2em" }} className="primary fill">
        Retour
      </Button>
    </Main>
  );
}
