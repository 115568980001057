import React, { useCallback, useState } from "react";
import { NavLink } from "react-router-dom";

export function Nav({ }) {
  const [isFloatingMenuVisible, setIsFloatingMenuVisible] = useState(false);
  const handleToggleMenu = useCallback(() => {
    setIsFloatingMenuVisible(!isFloatingMenuVisible);
  }, [isFloatingMenuVisible]);
  return (
    <menu>
      {/* navigation */}
      <nav className="menu">
        <NavLink
          className={({ isActive, isPending }) =>
            isPending ? "pending" : isActive ? "active" : ""
          }
          to="/"
          style={{ height: "max-content" }}
        >
          <svg
            data-name="Iconly/Bold/Home"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 40.95 43.105"
            width="1em"
            height="1em"
          >
            <path
              data-name="Home"
              d="M14.3 40.461v-6.59a3.057 3.057 0 0 1 3.068-3.046h6.195a3.08 3.08 0 0 1 2.17.892 3.035 3.035 0 0 1 .9 2.154v6.59a2.615 2.615 0 0 0 .767 1.869 2.654 2.654 0 0 0 1.876.775H33.5a7.458 7.458 0 0 0 5.266-2.154 7.349 7.349 0 0 0 2.184-5.221V16.955a5.329 5.329 0 0 0-1.93-4.1L24.643 1.457a6.676 6.676 0 0 0-8.51.153L2.084 12.855A5.332 5.332 0 0 0 0 16.955V35.71a7.422 7.422 0 0 0 7.449 7.395h4.13a2.654 2.654 0 0 0 2.663-2.625Z"
            />
          </svg>
        </NavLink>
        <NavLink
          className={({ isActive, isPending }) =>
            isPending ? "pending" : isActive ? "active" : ""
          }
          to="/interactive"
        >
          Cas cliniques interactifs
        </NavLink>
        <div className="menu-container">
          <NavLink
            className={({ isActive, isPending }) =>
              isPending ? "pending" : isActive ? "active" : ""
            }
            to="/videotheque"
            onClick={handleToggleMenu}
          >
            Vidéothèque
          </NavLink>
          <div className="floating-menu">
            <NavLink
              className={({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? "activeSub" : ""
              }
              to="/replay"
            >
              Webinars
            </NavLink>
            <hr className="seperator" />
            <NavLink
              className={({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? "activeSub" : ""
              }
              to="/best-of"
            >
              Best-of
            </NavLink>
            <hr className="seperator" />
            <NavLink
              className={({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? "activeSub" : ""
              }
              to="/allez-plus-loin"
            >
              Informations complémentaires
            </NavLink>
          </div>
        </div>
        <NavLink
          className={({ isActive, isPending }) =>
            isPending ? "pending" : isActive ? "active" : ""
          }
          to="/replay-video"
        >
          Webinar 2024
        </NavLink>

        {/* <NavLink className={({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? "active" : ""}
                to="/nothing-here">Nothing Here</NavLink> */}
      </nav>
    </menu>
  );
}

const HomeIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Iconly/Bold/Home"
    {...props}
  >
    <path
      fill="#fff"
      d="M14.3 40.461v-6.59a3.057 3.057 0 0 1 3.068-3.046h6.195a3.08 3.08 0 0 1 2.17.892 3.035 3.035 0 0 1 .9 2.154v6.59a2.615 2.615 0 0 0 .767 1.869 2.654 2.654 0 0 0 1.876.775H33.5a7.458 7.458 0 0 0 5.266-2.154 7.349 7.349 0 0 0 2.184-5.221V16.955a5.329 5.329 0 0 0-1.93-4.1L24.643 1.457a6.676 6.676 0 0 0-8.51.153L2.084 12.855A5.332 5.332 0 0 0 0 16.955V35.71a7.422 7.422 0 0 0 7.449 7.395h4.13a2.654 2.654 0 0 0 2.663-2.625Z"
      data-name="Home"
    />
  </svg>
);
