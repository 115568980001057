const template = {
  title: "title of the story",
  data: {
    initial: "NodeVideoStart",
    nodes: {
      //   ------------ QUIZ 1 flow ------------
      NodeVideoStart: {
        node_type: "video",
        content: [
          "/videoInteractive.mp4",
          { divert: "NodeAction0" },
          { end: "00:08" }, // default : the end of the video, optional from where it end
        ],
      },

      NodeAction0: {
        node_type: "action",
        content: [
          "Suivant",
          { position: "absolute", left: "80%", top: "85%" },
          { extra: "fill,danger" },
          { divert: 'NodeVideoCase1_1' },
        ]
      },
      NodeVideoCase1_1: {
        node_type: "video",
        content: [
          "/videoInteractive.mp4",
          { divert: "NodeAction1_1" },
          { label: "OUVRIR" },
          { start: "00:18" },
          { end: "01:07.5" },
          //   { audio: "effects.webm", spriteAfter: "menu2" },
        ],
      },
      NodeAction1_1: {
        node_type: "action",
        content: [
          "Suivant",
          { position: "absolute", left: "80%", top: "85%" },
          { extra: "fill,danger" },
          { divert: 'NodeAction1_2' },
          { blockNext: false, linkPath: "NodeVideoQSM1" },
        ]
      },
      NodeAction1_2: {
        node_type: "action",
        content: [
          "Comment pratiquer l'automesure",
          { extra: "fill,danger" },
          { position: "absolute", left: "35%", top: "85%" },
          { divert: 'NodeVideoPratiquer_1' },
        ]
      },

      NodeVideoPratiquer_1: {
        node_type: "video",
        content: [
          "/videoInteractive.mp4",
          { divert: "NodeAction1_4" },
          { label: "OUVRIR" },
          { start: "10:54" },
          { end: "11:15" },
          //   { audio: "effects.webm", spriteAfter: "menu2" },
        ],
      },
      NodeAction1_4: {
        node_type: "action",
        content: [
          "/interactive/button/suiviHTA.png",
          { image: true },
          { extra: "fill,danger" },
          { position: "absolute", left: "54%", top: "85%" },
          { divert: 'NodeAction1_3' },
          { blockNext: false, linkPath: "https://suivihta.net/", external: true },
        ]
      },
      NodeAction1_3: {
        node_type: "action",
        content: [
          "Revenir au cas",
          { extra: "fill,danger" },
          { position: "absolute", left: "77%", top: "85%" },
          { divert: 'NodeVideoCase1_1' },
        ]
      },


      NodeVideoQSM1: {
        node_type: "video",
        content: [
          "/videoInteractive.mp4",
          { divert: "NodeQuiz1" },
          { label: "OUVRIR" },
          { start: "01:08" },
          { end: "01:09" },
          //   { audio: "effects.webm", spriteAfter: "menu2" },
        ],
      },

      NodeQuiz1: {
        node_type: 'quiz',
        content: ['NodeQuiz1', { divert: 'nodeVideoAnserQuiz1' }, { extra: "ok,hello" }]
      },

      nodeVideoAnserQuiz1: {
        node_type: "video",
        content: [
          "/videoInteractive.mp4",
          { divert: "NodeAction1_5" },
          { label: "OUVRIR" },
          { start: "01:22" },
          { end: "01:27" },
          //   { audio: "effects.webm", spriteAfter: "menu2" },
        ],
      },

      NodeAction1_5: {
        node_type: "action",
        content: [
          "Voyons voir pourquoi",
          { actionType: "TimerButton" },
          { extra: "fill,danger" },
          { position: "absolute", left: "37%", top: "85%" },
          { divert: 'NodeVideoCase2_1' },
        ]
      },




      //   ------------ QUIZ 2 flow ------------

      NodeQuiz2: {
        node_type: 'quiz',
        content: ['NodeQuiz2', { divert: 'nodeVideoAnserQuiz2' }, { extra: "ok,hello" }]
      },


      NodeVideoCase2_1: {
        node_type: "video",
        content: [
          "/videoInteractive.mp4",
          { divert: "NodeAction_ShowSuiz_2_1" },
          { label: "OUVRIR" },
          { start: "01:33" },
          { end: "03:40" }, // TODO : timing is shit can't stop in the expected moment
        ],
      },
      NodeVideoRecommandation2: {
        node_type: "video",
        content: [
          "/videoInteractive.mp4",
          { divert: "NodeActionExternReco2" },
          { label: "OUVRIR" },
          { start: "09:27" },
          { end: "10:51" },
        ],
      },

      NodeVideoRecommandation2_1: {
        node_type: "video",
        content: [
          "/videoInteractive.mp4",
          { divert: "NodeAction_ShowSuiz_2_1_1" },
          { label: "OUVRIR" },
          { start: "09:27" },
          { end: "10:51" },
        ],
      },
      NodeVideoPreQuiz2: {
        node_type: "video",
        content: [
          "/videoInteractive.mp4",
          { divert: "NodeAction_ShowSuiz_2_1_1" },
          { label: "OUVRIR" },
          { start: "03:44" },
          { end: "04:56" },
        ],
      },





      NodeVideoPreQuiz2Enter: {
        node_type: "video",
        content: [
          "/videoInteractive.mp4",
          { divert: "NodeQuiz2" },
          { label: "OUVRIR" },
          { start: "04:59" },
          { end: "05:00" },
        ],
      },
      nodeVideoAnserQuiz2: {
        node_type: "video",
        content: [
          "/videoInteractive.mp4",
          { divert: "NodeAction2_5" },
          { label: "OUVRIR" },
          { start: "05:14" },
          { end: "5:18" },
        ],
      },
      NodeVideoCase2_3: {
        node_type: "video",
        content: [
          "/videoInteractive.mp4",
          { divert: "NodeAction_ShowSuiz_2_2" },
          { label: "OUVRIR" },
          { start: "00:12" },
          { end: "01:02" },
        ],
      },

      NodeVideoPratiquer2: {
        node_type: "video",
        content: [
          "/videoInteractive.mp4",
          { divert: "NodeActionExternPratiquer2" },
          { label: "OUVRIR" },
          { start: "10:30" },
          { end: "10:51.5" },
        ],
      },


      NodeAction_ShowSuiz_2_1: {
        node_type: "action",
        content: [
          "Suivant",
          { extra: "fill,danger" },
          { position: "absolute", left: "80%", top: "85%" },
          { divert: 'NodeAction2_2' },
          { blockNext: false, linkPath: "NodeVideoPreQuiz2" }
        ]
      },
      NodeAction2_2: {
        node_type: "action",
        content: [
          "Recommandations du Pr Hanon pour surveiller ses patients",
          { extra: "fill,danger" },
          { position: "absolute", left: "28%", top: "85%" },
          { divert: 'NodeVideoRecommandation2' },
        ]
      },

      NodeAction_ShowSuiz_2_1_1: {
        node_type: "action",
        content: [
          "Suivant",
          { extra: "fill,danger" },
          { position: "absolute", left: "80%", top: "85%" },
          { divert: 'NodeAction2_2_1_1' },
          { blockNext: false, linkPath: "NodeVideoPreQuiz2Enter" }
        ]
      },
      NodeAction2_2_1_1: {
        node_type: "action",
        content: [
          "Recommandations du Pr Hanon pour surveiller ses patients",
          { extra: "fill,danger" },
          { position: "absolute", left: "28%", top: "85%" },
          { divert: 'NodeVideoRecommandation2' },
        ]
      },

      NodeActionExternReco2: {
        node_type: "action",
        content: [
          "/interactive/button/suiviHTA.png",
          { image: true },
          { extra: "fill,danger" },
          { position: "absolute", left: "54%", top: "85%" },
          { divert: 'NodeAction_ShowSuiz_2' },
          { blockNext: false, linkPath: "https://suivihta.net/", external: true },
        ]
      },


      NodeAction_ShowSuiz_2: {
        node_type: "action",
        content: [
          "Revenir au cas",
          { extra: "fill,danger" },
          { position: "absolute", left: "77%", top: "85%" },
          { divert: 'NodeVideoPreQuiz2' },
        ]
      },

      NodeAction_ShowSuiz_2_2: {
        node_type: "action",
        content: [
          "Suivant",
          { extra: "fill,danger" },
          { position: "absolute", left: "80%", top: "85%" },
          { divert: 'NodeActionPratiquer2' },
          { blockNext: false, linkPath: "NodeVideoPreQuiz2" }
        ]
      },


      NodeActionPratiquer2: {
        node_type: "action",
        content: [
          "Comment pratiquer l'automesure",
          { extra: "fill,danger" },
          { position: "absolute", left: "35%", top: "85%" },
          { divert: 'NodeVideoCase2_1' },
        ]
      },

      NodeActionRevenir2: {
        node_type: "action",
        content: [
          "Revenir au cas",
          { extra: "fill,danger" },
          { position: "absolute", left: "77%", top: "85%" },
          { divert: 'NodeVideoCase2_3' },
        ]
      },
      NodeActionExternPratiquer2: {
        node_type: "action",
        content: [
          "/interactive/button/suiviHTA.png",
          { image: true },
          { extra: "fill,danger" },
          { position: "absolute", left: "54%", top: "85%" },
          { divert: 'NodeActionRevenir2' },
          { blockNext: false, linkPath: "https://suivihta.net/", external: true },
        ]
      },






      NodeAction2_5: {
        node_type: "action",
        content: [
          "Voyons voir pourquoi",
          { actionType: "TimerButton" },
          { extra: "fill,danger" },
          { position: "absolute", left: "37%", top: "85%" },
          { divert: 'NodeVideoCase3_1' },
        ]
      },

      //   ------------ QUIZ 3 flow ------------
      NodeQuiz3: {
        node_type: 'quiz',
        content: ['NodeQuiz3', { divert: 'nodeVideoAnserQuiz3' }, { extra: "ok,hello" }]
      },






      NodeVideoCase3_1: {
        node_type: "video",
        content: [
          "/videoInteractive.mp4",
          { divert: "NodeAction_ShowSuiz_3_1" },
          { label: "OUVRIR" },
          { start: "05:23" },
          { end: "05:36" },
        ],
      },
      NodeVideoRecommandation3: {
        node_type: "video",
        content: [
          "/videoInteractive.mp4",
          { divert: "NodeActionExternReco3" },
          { label: "OUVRIR" },
          { start: "09:27" },
          { end: "10:51" },
        ],
      },
      NodeVideoPreQuiz3: {
        node_type: "video",
        content: [
          "/videoInteractive.mp4",
          { divert: "NodeQuiz3" },
          { label: "OUVRIR" },
          { start: "05:39" },
          { end: "05:40" },
        ],
      },
      nodeVideoAnserQuiz3: {
        node_type: "video",
        content: [
          "/videoInteractive.mp4",
          { divert: "NodeAction3_5" },
          { label: "OUVRIR" },
          { start: "05:40" },
          { end: "05:43" },
        ],
      },
      NodeVideoCase3_3: {
        node_type: "video",
        content: [
          "/videoInteractive.mp4",
          { divert: "NodeAction_ShowSuiz_3_2" },
          { label: "OUVRIR" },
          { start: "00:12" },
          { end: "01:02" },
        ],
      },

      NodeVideoPratiquer3: {
        node_type: "video",
        content: [
          "/videoInteractive.mp4",
          { divert: "NodeActionExternPratiquer3" },
          { label: "OUVRIR" },
          { start: "10:30" },
          { end: "10:51.5" },
        ],
      },


      NodeAction_ShowSuiz_3_1: {
        node_type: "action",
        content: [
          "Suivant",
          { position: "absolute", left: "80%", top: "85%" },
          { divert: 'NodeAction3_2' },
          { extra: "fill,danger" },
          { blockNext: false, linkPath: "NodeVideoPreQuiz3" }
        ]
      },
      NodeAction3_2: {
        node_type: "action",
        content: [
          "Recommandations du Pr Hanon pour surveiller ses patients",
          { extra: "fill,danger" },
          { position: "absolute", left: "28%", top: "85%" },
          { divert: 'NodeVideoRecommandation3' },
        ]
      },
      NodeActionExternReco3: {
        node_type: "action",
        content: [
          "/interactive/button/suiviHTA.png",
          { image: true },
          { extra: "fill,danger" },
          { position: "absolute", left: "54%", top: "85%" },
          { divert: 'NodeAction_ShowSuiz_3' },
          { blockNext: false, linkPath: "https://suivihta.net/", external: true },
        ]
      },
      NodeAction_ShowSuiz_3: {
        node_type: "action",
        content: [
          "Revenir au cas",
          { extra: "fill,danger" },
          { position: "absolute", left: "77%", top: "85%" },
          { divert: 'NodeVideoPreQuiz3' },
        ]
      },

      NodeAction_ShowSuiz_3_2: {
        node_type: "action",
        content: [
          "Suivant",
          { extra: "fill,danger" },
          { position: "absolute", left: "80%", top: "85%" },
          { divert: 'NodeActionPratiquer3' },
          { blockNext: false, linkPath: "NodeVideoPreQuiz3" }
        ]
      },
      NodeActionPratiquer3: {
        node_type: "action",
        content: [
          "Comment pratiquer l'automesure",
          { extra: "fill,danger" },
          { position: "absolute", left: "35%", top: "85%" },
          { divert: 'NodeVideoCase3_1' },
        ]
      },

      NodeActionRevenir3: {
        node_type: "action",
        content: [
          "Revenir au cas",
          { extra: "fill,danger" },
          { position: "absolute", left: "77%", top: "85%" },
          { divert: 'NodeVideoCase3_3' },
        ]
      },
      NodeActionExternPratiquer3: {
        node_type: "action",
        content: [
          "/interactive/button/suiviHTA.png",
          { image: true },
          { extra: "fill,danger" },
          { position: "absolute", left: "54%", top: "85%" },
          { divert: 'NodeActionRevenir3' },
          { blockNext: false, linkPath: "https://suivihta.net/", external: true },
        ]
      },

      NodeAction3_5: {
        node_type: "action",
        content: [
          "Voyons voir pourquoi",
          { actionType: "TimerButton" },
          { extra: "fill,danger" },
          { position: "absolute", left: "37%", top: "85%" },
          { divert: 'NodeVideoCase4_1' },
        ]
      },
      //   ------------ QUIZ 4 flow ------------
      NodeQuiz4: {
        node_type: 'quiz',
        content: ['NodeQuiz4', { divert: 'nodeVideoAnserQuiz4' }, { extra: "ok,hello" }]
      },
      NodeVideoCase4_1: {
        node_type: "video",
        content: [
          "/videoInteractive.mp4",
          { divert: "NodeAction_ShowSuiz_4_1" },
          { label: "OUVRIR" },
          { start: "05:49" },
          { end: "07:12" },
        ],
      },
      NodeVideoPreQuiz4: {
        node_type: "video",
        content: [
          "/videoInteractive.mp4",
          { divert: "NodeQuiz4" },
          { label: "OUVRIR" },
          { start: "07:18" },
          { end: "07:19" },
        ],
      },
      nodeVideoAnserQuiz4: {
        node_type: "video",
        content: [
          "/videoInteractive.mp4",
          { divert: "NodeAction4_5" },
          { label: "OUVRIR" },
          { start: "07:21" },
          { end: "07:44" },
        ],
      },
      NodeVideoRecommandation4: {
        node_type: "video",
        content: [
          "/videoInteractive.mp4",
          { divert: "NodeActionExternReco4" },
          { label: "OUVRIR" },
          { start: "09:27" },
          { end: "10:51" },
        ],
      },


      NodeVideoCase4_3: {
        node_type: "video",
        content: [
          "/videoInteractive.mp4",
          { divert: "NodeAction_ShowSuiz_4_2" },
          { label: "OUVRIR" },
          { start: "00:12" },
          { end: "01:02" },
        ],
      },

      NodeVideoPratiquer4: {
        node_type: "video",
        content: [
          "/videoInteractive.mp4",
          { divert: "NodeActionExternPratiquer4" },
          { label: "OUVRIR" },
          { start: "10:30" },
          { end: "10:51.5" },
        ],
      },


      NodeAction_ShowSuiz_4_1: {
        node_type: "action",
        content: [
          "Suivant",
          { position: "absolute", left: "80%", top: "85%" },
          { extra: "fill,danger" },
          { divert: 'NodeAction4_2' },
          { blockNext: false, linkPath: "NodeVideoPreQuiz4" }
        ]
      },
      NodeAction4_2: {
        node_type: "action",
        content: [
          "Recommandations du Pr Hanon pour surveiller ses patients",
          { extra: "fill,danger" },
          { position: "absolute", left: "28%", top: "85%" },
          { divert: 'NodeVideoRecommandation4' },
        ]
      },
      NodeActionExternReco4: {
        node_type: "action",
        content: [
          "/interactive/button/suiviHTA.png",
          { image: true },
          { position: "absolute", left: "54%", top: "85%" },
          { extra: "fill,danger" },
          { divert: 'NodeAction_ShowSuiz_4' },
          { blockNext: false, linkPath: "https://suivihta.net/", external: true },
        ]
      },
      NodeAction_ShowSuiz_4: {
        node_type: "action",
        content: [
          "Revenir au cas",
          { position: "absolute", left: "77%", top: "85%" },
          { extra: "fill,danger" },
          { divert: 'NodeVideoPreQuiz4' },
        ]
      },

      NodeAction_ShowSuiz_4_2: {
        node_type: "action",
        content: [
          "Suivant",
          { position: "absolute", left: "80%", top: "85%" },
          { extra: "fill,danger" },
          { divert: 'NodeActionPratiquer3' },
          { blockNext: false, linkPath: "NodeVideoPreQuiz4" }
        ]
      },
      NodeActionPratiquer4: {
        node_type: "action",
        content: [
          "Comment pratiquer l'automesure",
          { position: "absolute", left: "35%", top: "85%" },
          { extra: "fill,danger" },
          { divert: 'NodeVideoCase3_1' },
        ]
      },

      NodeActionRevenir4: {
        node_type: "action",
        content: [
          "Revenir au cas",
          { position: "absolute", left: "77%", top: "85%" },
          { extra: "fill,danger" },
          { divert: 'NodeVideoCase4_3' },
        ]
      },
      NodeActionExternPratiquer4: {
        node_type: "action",
        content: [
          "/interactive/button/suiviHTA.png",
          { image: true },
          { position: "absolute", left: "54%", top: "85%" },
          { extra: "fill,danger" },
          { divert: 'NodeActionRevenir4' },
          { blockNext: false, linkPath: "https://suivihta.net/", external: true },
        ]
      },

      NodeAction4_5: {
        node_type: "action",
        content: [
          "Suivant",
          { position: "absolute", left: "80%", top: "85%" },
          { extra: "fill,danger" },
          { divert: 'NodeVideoCase5_1' },
        ]
      },
      //   ------------ Vote flow ------------



      NodeQuiz5: {
        node_type: 'quiz',
        content: ['NodeQuiz5', { divert: 'NodeVideoEnd' }, { extra: "ok,hello" }]
      },
      NodeVideoCase5_1: {
        node_type: "video",
        content: [
          "/videoInteractive.mp4",
          { divert: "NodeAction_ShowSuiz_5_1" },
          { label: "OUVRIR" },
          { start: "7:47" },
          { end: "8:59" },
        ],
      },
      NodeVideoRecommandation5: {
        node_type: "video",
        content: [
          "/videoInteractive.mp4",
          { divert: "NodeActionExternReco5" },
          { label: "OUVRIR" },
          { start: "09:27" },
          { end: "10:51" },
        ],
      },

      NodeVideoEnd: {
        node_type: "video",
        content: [
          "/videoInteractive.mp4",
          { divert: null },
          { label: "OUVRIR" },
          { start: "11:30" },
        ],
      },
      NodeVideoPreQuiz5: {
        node_type: "video",
        content: [
          "/videoInteractive.mp4",
          { divert: "NodeQuiz5" },
          { start: "10:53" },
          { end: "10:53" },
        ],
      },

      NodeAction_ShowSuiz_5_1: {
        node_type: "action",
        content: [
          "Suivant",
          { position: "absolute", left: "80%", top: "85%" },
          { extra: "fill,danger" },
          { divert: 'NodeAction5_2' },

          { blockNext: false, linkPath: "NodeVideoPreQuiz5" }
        ]
      },
      NodeAction5_2: {
        node_type: "action",
        content: [
          "Recommandations du Pr Hanon pour surveiller ses patients",
          { position: "absolute", left: "28%", top: "85%" },
          { extra: "fill,danger" },
          { divert: 'NodeVideoRecommandation5' },
        ]
      },
      NodeActionExternReco5: {
        node_type: "action",
        content: [
          "/interactive/button/suiviHTA.png",
          { image: true },
          { position: "absolute", left: "54%", top: "85%" },
          { extra: "fill,danger" },
          { divert: 'NodeAction_ShowSuiz_5' },
          { blockNext: false, linkPath: "https://suivihta.net/", external: true },
        ]
      },
      NodeAction_ShowSuiz_5: {
        node_type: "action",
        content: [
          "Revenir au cas",
          { position: "absolute", left: "77%", top: "85%" },
          { extra: "fill,danger" },
          { divert: 'NodeVideoPreQuiz5' },
        ]
      },

    },


  },
};

export default template;
