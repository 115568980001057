const template = {
  title: "title of the story",
  data: {
    nodes: {
      // videos
      NodeVideoStart: {
        node_type: "video",
        content: [
          "/videoInteractive.mp4",
          { divert: "NodeVideoMenuGate" },
          { end: "00:21" }, // default : the end of the video, optional from where it end
          { checkPoint: "checkPoint name" },
          { audio: "effects.webm", spriteAfter: "menu1" },
        ],
      },
      NodeVideoMenuGate: {
        node_type: "video",
        content: [
          "/videoInteractive.mp4",
          { divert: "NodeGate1" },
          { start: "00:21" },
          { end: "00:21" }, // default : the end of the video, optional from where it end
          { checkPoint: "checkpoint name" },
        ],
      },
      NodeVideoOuvrir: {
        node_type: "video",
        content: [
          "/videoInteractive.mp4",
          { divert: "NodeVideoMenuGate" },
          { label: "OUVRIR" },
          { start: "00:21" },
          { end: "00:53.350" },
          { audio: "effects.webm", spriteAfter: "menu2" },
        ],
      },
      NodeVideoFermerFixed: {
        node_type: "video",
        content: [
          "/videoInteractive.mp4",
          { divert: "NodeActionFermer" },
          { label: "FERMER" },
          { start: "01:12" },
          { end: "01:12" },
        ],
      },
      NodeVideoFermer: {
        node_type: "video",
        content: [
          "/videoInteractive.mp4",
          { divert: "NodeVideoPreEnd" },
          { label: "FERMER" },
          { start: "01:12" },
          { end: "01:27" },
        ],
      },
      NodeVideoEnd: {
        node_type: "video",
        content: [
          "/videoInteractive.mp4",
          { divert: null },
          { start: "01:53" },
          { end: "01:55" },
        ],
      },

      NodeVideoPreEnd: {
        node_type: "video",
        content: [
          "/videoInteractive.mp4",
          { divert: "NodeVideoPreEndRefaire" },
          { start: "01:27" },
          { end: "01:52" },
        ],
      },
      NodeVideoPreEndRefaire: {
        node_type: "video",
        content: [
          "/videoInteractive.mp4",
          { divert: "NodeVideoPreEndContinue" },
          { start: "01:52" },
          { end: "02:00" },
        ],
      },
      NodeVideoPreEndContinue: {
        node_type: "video",
        content: [
          "/videoInteractive.mp4",
          { divert: "NodeActionEnd" },
          { start: "02:00" },
          { end: "02:06" },
        ],
      },
      NodeVideoInhaler: {
        node_type: "video",
        content: [
          "/videoInteractive.mp4",
          { label: "INHALER" },
          { divert: "NodeActionExpirer" },
          { start: "00:53.18" },
          { end: "01:01.600" },
        ],
      },
      NodeVideoInhaler1: {
        node_type: "video",
        content: [
          "/videoInteractive.mp4",
          { label: "INHALER" },
          { divert: "NodeActionInspirer" },
          { start: "01:01.600" },
          { end: "01:05" },
        ],
      },
      NodeVideoInhaler2: {
        node_type: "video",
        content: [
          "/videoInteractive.mp4",
          { label: "INHALER" },
          { divert: "NodeActionExpirer1" },
          { start: "01:05" },
          { checkPoint: "ss" },
          { end: "01:08" },
        ],
      },
      NodeVideoInhaler3: {
        node_type: "video",
        content: [
          "/videoInteractive.mp4",
          { label: "INHALER" },
          { divert: "NodeVideoMenuGate" },
          { start: "01:08" },
          { end: "01:11.17" },
          { audio: "effects.webm", spriteAfter: "menu3" },
        ],
      },
      NodeActionOuvrir: {
        node_type: "action",
        content: [
          "/svg/relvar/ouvrir.svg",
          { label: "OUVRIR" },
          { image: true },
          { extra: "jump" },
          { position: "absolute", left: "73%", top: "15%" },
          { divert: "NodeVideoOuvrir" },
          { audio: "effects.webm", spriteBefore: "ouvrir" },
        ],
      },
      NodeActionFermer: {
        node_type: "action",
        content: [
          "/svg/relvar/fermer.svg",
          { position: "absolute", left: "73%", top: "15%" },
          { label: "FERMER" },
          { image: true },
          { divert: "NodeVideoFermer" },
          { extra: "jump" },
          { audio: "effects.webm", spriteBefore: "fermer" },
        ],
      },

      NodeGate1: {
        node_type: "gate",
        content: [
          "Now that you know which tasks to perform, what do you want to do?",
          { extra: "classic" },
          {
            option: "OUVRIR",
            visited: "ouvrir",
            linkPath: "NodeTunnelOuvrir",
            enable: "",
          },
          {
            option: "INHALER",
            visited: "inhaler",
            linkPath: "NodeTunnelInhaler",
            enable: "ouvrir",
          },
          {
            option: "FERMER",
            visited: "fermer",
            linkPath: "NodeTunnelFermer",
            enable: "ouvrir,inhaler",
          },
        ],
      },
      NodeActionHandPosition: {
        node_type: "gate",
        content: [
          "text",
          { audio: "effects.webm", spriteBefore: "inhalation1" },
          { extra: "optionMiddle hand" },
          { label: "INHALER" },
          {
            option: "bottommmmmmmm",
            linkPath: "NodeVideoInhaler",
            parametre: "false",
            enable: "",
          },
          {
            option: "bottommmmmmmm",
            linkPath: "NodeVideoInhaler",
            parametre: "true",
            enable: "",
          },
        ],
      },
      NodeActionExpirer: {
        node_type: "action",
        content: [
          "EXPIRER",
          { actionType: "hold" },
          { label: "INHALER" },
          { position: "absolute", left: "80%", top: "10%" },
          { divert: "NodeVideoInhaler1" },
          { audio: "effects.webm", spriteBefore: "inhalation2" },
        ],
      },
      NodeActionInspirer: {
        node_type: "action",
        content: [
          "INSPIRER",
          { actionType: "hold" },
          { label: "INHALER" },
          { position: "absolute", left: "80%", top: "10%" },
          { divert: "NodeVideoInhaler2" },
          { audio: "effects.webm", spriteBefore: "inhalation4" },
        ],
      },
      NodeActionExpirer1: {
        node_type: "action",
        content: [
          "EXPIRER",
          { label: "INHALER" },
          { actionType: "hold" },
          { position: "absolute", left: "80%", top: "10%" },
          { divert: "NodeVideoInhaler3" },
          { audio: "effects.webm", spriteBefore: "inspirer5" },
        ],
      },
      NodeActionEnd: {
        node_type: "action",
        content: [
          "Refaire l'expérience",
          { extra: "refaire,buttonText" },
          { position: "absolute", left: "60%", top: "45%" },
          { divert: "NodeVideoEnd" },
        ],
      },
      NodeTunnelOuvrir: {
        node_type: "tunnel",
        content: [
          "text",
          { flagName: "ouvrir" },
          { divert: "NodeActionOuvrir" },
        ],
      },
      NodeTunnelInhaler: {
        node_type: "tunnel",
        content: [
          "text",
          { flagName: "inhaler" },
          { divert: "NodeActionHandPosition" },
        ],
      },
      NodeTunnelFermer: {
        node_type: "tunnel",
        content: [
          "text",
          { flagName: "fermer" },
          { divert: "NodeVideoFermerFixed" },
        ],
      },
    },

    initial: "NodeVideoStart",
  },
};

export default template;
